<template>
  <div class="subscriptions-news">
    <div v-show="!submitFormShow">
      <div class="inputBlock required" :class="{ error: v$.email.$error }">
        <p class="label">Введите почту</p>
        <input v-model="email" type="email" />
      </div>
      <div class="inputBlock required" :class="{ error: v$.newsTypeId.$error }">
        <p class="label">Выберите категорию новостей</p>
        <SelectComponent v-model="newsTypeId" placeholder="Категории" :async="false" :options="categoryNews" />
      </div>
      <ButtonComponent @click="submitOnNews()" title="Подписаться" />
    </div>
    <transition
      name="accordion"
      @enter="startTransition"
      @after-enter="endTransition"
      @before-leave="startTransition"
      @after-leave="endTransition"
    >
      <div class="text-center" v-show="submitFormShow">
        <p class="subscriptions-news__done">Подписка оформлена.</p>
        <ButtonComponent @click="close" title="Закрыть" />
      </div>
    </transition>
  </div>
</template>

<script>
  import { useVuelidate } from '@vuelidate/core';
  import { email, required } from '@vuelidate/validators';

  import NsiApi from '@/app/nsi/api/nsi';

  import Constants from '@/common/constants';

  import Api from './api/subscriptions';
  import ButtonComponent from './ui/ButtonComponent.vue';
  import SelectComponent from './ui/SelectComponent.vue';

  export default {
    name: 'subscriptionNews',
    components: {
      ButtonComponent,
      SelectComponent,
    },
    data() {
      return {
        categoryNews: [],
        newsTypeId: null,
        email: '',
        submitFormShow: false,
      };
    },
    mounted() {
      NsiApi.search('NsiNewsType', { pageNumber: 1, pageSize: 0, isActive: true }).then((response) => {
        this.categoryNews = response.data.items.map((x) => ({ value: x.id, label: x.name }));
      });
    },
    created() {},
    methods: {
      submitOnNews() {
        this.v$.$touch();
        if (this.v$.$invalid) {
          return;
        }

        Api.submitOnNews({
          email: this.email,
          newsTypeId: this.newsTypeId,
        })
          .then(() => {
            this.submitFormShow = true;
          })
          .catch((error) => {
            console.error(error?.response?.data);
            var mess = error?.response?.data ? error?.response?.data : 'Произошла ошибка';
            Constants.alert.fire('Подписка', mess, 'error');
          });
      },
      close() {
        this.submitFormShow = false;
        this.$emit('close');
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        email: { required, email },
        newsTypeId: { required },
      };
    },
  };
</script>

<style lang="scss" scoped>
  .subscriptions-news {
    padding: 30px 0px;
    &__done {
      font-size: 16px;
      margin-bottom: 16px;
    }
    .inputBlock {
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;
      width: 100%;
      .label {
        margin-bottom: 8px;
      }
      input {
        border-radius: 24px;
        border: 1px solid #d3dfe5;
      }

      &.error input,
      &.error .multiselect {
        border-color: red;
      }
    }
    button {
      max-width: 190px;
    }
  }

  .required .label::after {
    content: '*';
    color: red;
    margin-left: 2px;
  }
</style>
