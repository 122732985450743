export default {
  namespaced: true,
  state: {
    hideClutterPrintContent: false,
    hideClutterContent: false,
  },

  mutations: {
    setHideClutterPrintContent(state, value) {
      state.hideClutterPrintContent = value;
    },
    setHideClutterContent(state, value) {
      state.hideClutterContent = value;
    },
  },

  modules: {},
};
