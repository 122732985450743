import axios from 'axios';

export default {
  submitOnNews: function (data) {
    return axios.post('/api/newsSubscription/createUser/', data);
  },
  refuseSubscription: function (uuid) {
    return axios.delete('/api/newsSubscription/' + uuid);
  },
};
