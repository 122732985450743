<template>
  <IconComponent class="bvi-icon bvi-open" name="ico-Eye" />
</template>

<script>
  import { Bvi } from '@/app/shared/components/bvi/bvi.js';

  import IconComponent from './ui/IconComponent.vue';

  export default {
    components: { IconComponent },
    mounted() {
      try {
        // eslint-disable-next-line no-undef, no-unused-vars
        let bviInit = new Bvi({
          target: '.bvi-open',
          theme: 'white', // Цвет сайта
          font: 'Ubuntu', // Шрифт
          fontSize: 16, // Размер шрифта
          letterSpacing: 'normal', // Межбуквенный интервал
          lineHeight: 'normal', // Междустрочный интервал
          reload: true, // Перезагрузка страницы при выключении плагина
          fixed: false, // Фиксирование панели для слабовидящих вверху страницы
          speech: false, // Синтез речи
          panelHide: false, // Скрывает панель для слабовидящих и показывает иконку панели.
        });
      } catch (e) {
        console.log(e);
      }
    },
  };
</script>

<style lang="css">
  @import '@/app/shared/components/bvi/bvi.css';
  .bvi-icon {
    cursor: pointer;
  }
</style>
