export const routes = [
  {
    path: '/market-access',
    name: 'app-market-access',
    component: () => import('@/app/modules/MarketsAccess/MarketAccess'),
  },
  {
    path: '/market-access/restriction/:id?',
    name: 'app-market-access-restriction',
    component: () => import('@/app/modules/MarketsAccess/components/restriction/Restriction'),
  },
  {
    path: '/market-access/duties-and-taxes/:id?',
    name: 'app-market-access-taxes',
    component: () => import('@/app/modules/MarketsAccess/components/Taxes'),
  },
  {
    path: '/market-access/action-list',
    name: 'app-market-access-action-list',
    component: () => import('@/app/modules/MarketsAccess/components/ActionsList'),
  },
  {
    path: '/market-access/action-list/:id',
    name: 'app-market-access-action-list-item',
    component: () => import('@/app/modules/MarketsAccess/components/ActionListItem'),
  },
  {
    path: '/market-access/support',
    name: 'app-market-access-support',
    component: () => import('@/app/modules/MarketsAccess/components/Support'),
  },
  {
    path: '/market-access/monitoring-world-prices',
    name: 'monitoring-world-prices',
    component: () => import('@/app/modules/MarketsAccess/components/MonitoringWorldPrices'),
  },
];
