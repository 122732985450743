// plugins/table-arrow.js
import './index.scss';

export default {
  install: (app, options) => {
    //запускается на всех страницах
    let tableArrowPressTimer,
      tableArrowUp = function () {
        clearInterval(tableArrowPressTimer);
      };
    let tableArrow = function () {
      let selector = options?.selector || '.table-container',
        elAll = document.querySelectorAll(selector);
      for (let i = 0; i < elAll.length; i++) {
        let el = elAll[i],
          speed = options?.speed || 45;
        var container = el.parentElement;
        el.id = 'tableArrow' + i;
        if (el !== null) {
          if (el.scrollWidth !== el.clientWidth && el.clientWidth > 0) {
            let tableArrow = container.querySelector('.tableArrow'); //проверка на наличие стрелок
            if (!container.contains(tableArrow)) {
              let template = `
            <div class="tableArrow">
              <div class="tableArrow__container">
                <button id="js-tableArrow-left${i}" class="tableArrow__arrow tableArrow__arrow_left tableArrow__arrow_deactive"></button>
                <button id="js-tableArrow-right${i}" class="tableArrow__arrow tableArrow__arrow_right"></button>
              </div>
            </div>`;

              el.insertAdjacentHTML('beforebegin', template);
              tableArrow = container.querySelector('.tableArrow');
            }
            let arrowRight = document.getElementById('js-tableArrow-right' + i),
              arrowLeft = document.getElementById('js-tableArrow-left' + i),
              arrowFunc = function (arrow, id) {
                let table = document.getElementById('tableArrow' + id);
                let checkScroll = table.scrollWidth - table.offsetWidth,
                  scrollPos = Math.ceil(table.scrollLeft),
                  move = arrow.id == arrowRight.id ? 'right' : arrow.id == arrowLeft.id ? 'left' : '';
                table.scrollBy({
                  left: move === 'right' ? speed : move === 'left' ? -speed : 0,
                  behavior: 'smooth',
                });
                if (checkScroll <= scrollPos) {
                  //конечная точка справа
                  arrowRight.classList.add('tableArrow__arrow_deactive');
                }
                if (scrollPos === 0) {
                  //конечная точка слева
                  arrowLeft.classList.add('tableArrow__arrow_deactive');
                }
                if (move === 'right') {
                  arrowLeft.classList.remove('tableArrow__arrow_deactive');
                } else if (move === 'left') {
                  arrowRight.classList.remove('tableArrow__arrow_deactive');
                }
              },
              down = function (e) {
                tableArrowPressTimer = setInterval(arrowFunc.bind(null, e.target, i), 100);
              };
            tableArrow.onmousedown = down; //по зажатию
            tableArrow.ontouchstart = down; //на мобильных устройствах
            tableArrow.onclick = function (e) {
              //по клику и на мобильных устройствах
              arrowFunc(e.target, i);
            };

            document.removeEventListener('mouseup', tableArrowUp); //удаление на всякий случай, для каких-нибудь браузеров
            document.removeEventListener('mouseleave', tableArrowUp);
            document.removeEventListener('touchend', tableArrowUp);
            document.addEventListener('mouseup', tableArrowUp); // по отжатию ЛКМ
            document.addEventListener('mouseleave', tableArrowUp); // по отжатию ЛКМ
            document.addEventListener('touchend', tableArrowUp); // по отжатию на мобильных устройствах
          } else {
            let tableArrowCheck = container.querySelector('.tableArrow');
            if (tableArrowCheck) tableArrowCheck.remove(); //удалить если прокрутки нет
          }
        }
      }
    };
    setInterval(tableArrow, 500); //при загрузке
    window.addEventListener('resize', tableArrow); //при повороте и ресайзе экрана
  },
};
