import { createStore } from 'vuex';

import exportPotentialMap from '@/app/modules/ExportPotential/state/map';
import measure from '@/app/modules/MeasuresTtrStd/store';
import navigator from '@/app/modules/MemberNavigator/store';
import auth from '@/app/modules/auth/store';

import home from '../../modules/Home/state/index';
import agreements from '../../modules/InternationalTrade/store/index';
import marketAccess from '../../modules/MarketsAccess/state/index';
import nsitnved from './nsitnved';
import selects from './selects/index';

export default createStore({
  state: {
    news: [],
  },
  getters: {
    allNews(state) {
      return state.news;
    },
  },
  mutations: {
    updateNews(state, news) {
      state.news = news;
    },
  },
  actions: {
    getNews(ctx) {
      setTimeout(() => {
        const news = [
          {
            title: 'Готовятся новые меры увеличения экспорта древесины из Индии',
            date: '11.12.2022',
            img: require('../../../assets/img/home/newsSlide1.jpg'),
            link: '/market-access/action-list/India',
          },
          {
            title: 'Обзор СМИ по внешнеэкономической тематике за период с 22.11.2021 по 28.11.2021',
            date: '12.07.2022',
            img: require('../../../assets/img/home/newsSlide4.jpg'),
            link: '/market-access/action-list/India',
          },
          {
            title: 'Пекин заявил, что встреча глав правительств КНР и РФ нацелена на стабилизацию поставок',
            date: '12.02.2022',
            img: require('../../../assets/img/home/newsSlide3.jpg'),
            link: '/market-access/action-list/India',
          },
          {
            title: 'Представители России и Франции договорились о развитии сотрудничества в сфере инноваций',
            date: '10.12.2022',
            img: require('../../../assets/img/home/newsSlide2.jpg'),
            link: '/market-access/action-list/India',
          },
        ];
        ctx.commit('updateNews', news);
      }, 200);
    },
  },
  modules: {
    auth,
    navigator,
    home,
    marketAccess,
    selects,
    exportPotentialMap,
    agreements,
    ...measure,
    nsitnved,
  },
});
