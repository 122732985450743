import { authAxios } from '@/common/axios';
import runtimeConfig from '@/common/runtime-config';

export default {
  login: function (request) {
    request.clientId = runtimeConfig.oidc.client_id;
    return authAxios.post(`api/auth/login`, request);
  },
  logout: function () {
    return authAxios.post(`api/auth/logout`, { clientId: runtimeConfig.oidc.client_id });
  },
  getProfileData: async function () {
    return await authAxios.get(`api/user/profile`);
  },
  register: function (request) {
    return authAxios.post(`api/employee/register`, request);
  },
  confirm: function (code) {
    return authAxios.get(`api/employee/confirmregistration?code=${code}`, {});
  },
};
