export const routes = [
  { path: '/interactive-path', component: () => import('@/app/modules/MemberNavigator/views/interactivePath') },
  {
    path: '/interactive-path-steps/:id',
    component: () => import('@/app/modules/MemberNavigator/views/interactivePathSteps'),
  },
  { path: '/review-changes/list', component: () => import('@/app/modules/MemberNavigator/views/reviewChanges') },
  {
    path: '/review-changes/list/item/:id',
    name: 'reviewItem',
    component: () => import('@/app/modules/MemberNavigator/views/reviewItem'),
  },
];
