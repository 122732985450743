import store from '@/app/shared/state/index';

export const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/app/modules/auth/views/LoginComponent'),
    meta: { isProtected: false },
  },
  {
    path: '/auth/callback',
    name: 'Callback',
    component: () => import('@/app/modules/auth/views/CallbackComponent'),
    meta: { isProtected: false },
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import('@/app/modules/auth/views/LogoutComponent'),
    meta: { isProtected: false },
  },
  {
    path: '/regconfirm/:code',
    component: () => import('@/app/modules/auth/views/RegConfirmation'),
    meta: { isProtected: false },
  },
];

export const registerGuards = (router) => {
  router.beforeEach(async (to /*, from*/) => {
    if (to.meta?.isProtected === true && !store.getters['auth/oidc/isAuthenticated']) {
      return { path: '/login', query: { returnUrl: to.fullPath } };
    }
    // console.log('auth guard', to, from)
  });
};
