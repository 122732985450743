import OrgApi from '@/app/admin/api/organization';
import { getDefaultState } from '@/app/modules/MeasuresTtrStd/store/initiativeRegistry/utils';

import Api from '../../api/feedback';

export async function setUser({ rootState, commit }) {
  let profile = rootState.auth?.oidc?.profile;

  if (profile == null) {
    profile = JSON.parse(localStorage.getItem('userProfileData'));
  }

  commit(`setEmployee`, profile?.employee ?? {});

  let orgId = profile?.employee?.organizationId;
  if (orgId) {
    const response = await OrgApi.find(orgId);
    commit('setOrganization', response.data);
  }
}

export function createInitiative({ state }) {
  return Api.create(state.initiative);
}

export async function findInitiative({ commit }, id) {
  const response = await Api.find(id);
  const newInitiative = response.data;

  if (!newInitiative.proposedMeasureJustificationDto) {
    const proposedMeasureJustificationDto = {};
    newInitiative.proposedMeasureJustificationDto = Object.keys(
      getDefaultState().initiative.proposedMeasureJustificationDto,
    ).forEach((key) => (proposedMeasureJustificationDto[key] = null));
    newInitiative.proposedMeasureJustificationDto = proposedMeasureJustificationDto;
  }

  commit('setInitiative', newInitiative);
}

export async function updateInitiative({ state }) {
  const response = await Api.update(state.initiative);
  state.initiative = response.data;
  return response;
}
