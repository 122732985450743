import axios from 'axios';

export default {
  search: function (request) {
    return axios.get('/api/auditPage/search', { params: request });
  },
  visitPage: function (request) {
    return axios.post('/api/auditPage/visitPage', request);
  },
};
