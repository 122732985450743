import DutyApi from '@/app/modules/MarketsAccess/api/dutyApi';
import restriction from '@/app/modules/MarketsAccess/state/restriction';
import RestrictionMeasureApi from '@/app/nsi/api/restrictionMeasure.js';

export default {
  state: {
    restrictionData: [],
    accessNews: [],
    dutyData: null,
  },
  getters: {
    getRestrictionProduct(state) {
      return state.restrictionData;
    },
    getDutyData(state) {
      return state.dutyData;
    },
  },
  mutations: {
    updateRestrictionData(state, restrictionData) {
      state.restrictionData = restrictionData;
    },
    updateDutyData(state, dutyData) {
      state.dutyData = dutyData;
    },
  },
  actions: {
    downloadRestrictionMeasureApi(ctx) {
      RestrictionMeasureApi.search({ pageNumber: 1, pageSize: 0, isActive: true }).then((response) => {
        const restrictionsData = [];
        response.data.items.map((restriction) => {
          if (restriction.tradeItemTypeId == 1) {
            restrictionsData.push({
              initiators: restriction.countries?.map((x) => ({
                name: x.nameFull,
                countryId: x.countryId,
                code: x.code,
              })),
              initiatorsAll: restriction.allCountries,
              targetId: 235,
              products: restriction.tnveds?.map((x) => ({ name: x.name, id: x.id, code: x.code })),
              productsAll: restriction.allTradeItems,
            });
          }
        });
        ctx.commit('updateRestrictionData', restrictionsData);
      });
    },
    downloadDutyData(ctx, request) {
      DutyApi.search(request)
        .then((response) => {
          let dutyData = {};
          response.data.items.forEach((item) => {
            if (item.countryImporter) {
              if (item.countryImporter.code) {
                if (dutyData[item.countryImporter.code] !== undefined) {
                  dutyData[item.countryImporter.code].counter++;
                } else {
                  dutyData[item.countryImporter.code] = {
                    counter: 1,
                    label: item.countryImporter.name,
                    picture: item.countryImporter.picture,
                  };
                }
              }
            }
          });
          ctx.commit('updateDutyData', dutyData);
        })
        .catch((e) => {
          console.error(e);
        });
    },
  },
  modules: {
    restriction,
  },
};
