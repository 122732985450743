import axios from 'axios';

import { authAxios } from '@/common/axios';

/**
 * Обший универсальный запрос
 * @param {Vuex} context контекст vuex
 * @param {Object} settings настройки
 * @param {String} settings.url путь запроса
 * @param {String} settings.method тип запроса, по умолчанию GET
 * @param {String} settings.mutate название мутации, если есть то примениться
 * @param {String} settings.name название метода в котором был произведен вызов
 * @param {String[]} settings.pathParams параметры которые нужно включить в путь, тем самым они исключаются из params
 * @param {Object} params параметры для запроса
 * @param {Boolean} settings.requestAuth меняет host запроса с admin на auth
 * @returns {Object} результат
 */
export default async function request(context, settings, params) {
  const { commit } = context;
  const { method = 'get', mutate, name = '', pathParams = [], requestAuth = false } = settings;
  let { url } = settings;
  const config = {};
  let result = null;
  let dataSend = null;
  const typeRequest = requestAuth ? authAxios : axios;

  if (pathParams.length) {
    url = pathParams.reduce((total, paramName) => {
      if (total.includes(paramName) && paramName in params) {
        total = total.replace(`:${paramName}`, params[paramName]);
      }

      return total;
    }, url);

    params = Object.keys(params)
      .filter((key) => !pathParams.includes(key))
      .reduce((total, key) => ({ ...total, [key]: params[key] }), {});
  }

  dataSend = params;

  try {
    if (method === 'post' || method === 'put' || method === 'patch') {
      result = await typeRequest[`${method}`](url, dataSend, config);
    } else {
      result = await typeRequest[`${method}`](url, { params });
    }

    if (mutate && commit) {
      commit(mutate, result);
    }

    return result;
  } catch (error) {
    console.error(`Ошибка: Не получилось выполнить метод: ${name}`);
    console.error('Формат данных который был получен: ', result);
    console.error(error);
    console.log('result: ', result);
    console.log('error: ', error);
    console.log('error.response.data: ', error.response.data);
    console.log('error.response.data.errors: ', error.response.data.errors);
    return {
      data: result,
      error,
      status: error.response.status,
      message: error.response.statusText,
    };
  }
}
