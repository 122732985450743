<template>
  <Header />

  <router-view />

  <Footer />
</template>

<script>
  import auditPage from '@/app/admin/api/auditPage';
  import Footer from '@/app/shared/components/Footer';
  import Header from '@/app/shared/components/Header';

  export default {
    name: 'App',
    components: { Footer, Header },

    watch: {
      $route: function (to) {
        if (to.matched && to.matched.length > 0) {
          let request = {
            url: window.location.href,
            isInternal: false,
            routeName: to.matched[0].path,
          };
          auditPage.visitPage(request);
        }
      },
    },
  };
</script>

<style></style>
