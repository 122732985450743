// eslint-disable-next-line no-undef
__webpack_nonce__ = window.__webpack_nonce__;

import '@fortawesome/fontawesome-free/css/all.min.css';
import FloatingVue from 'floating-vue';
import { Tooltip } from 'floating-vue';
import 'floating-vue/dist/style.css';
import moment from 'moment';
import { createApp } from 'vue';
import VueApexCharts from 'vue3-apexcharts';
import Select2 from 'vue3-select2-component';

import App from '@/app/App';
import router from '@/app/app-routes';
import directiveClickOutside from '@/app/directive/click-outside.js';
import Pagination from '@/app/shared/components/ui/Pagination';
import store from '@/app/shared/state/index';

import { configAxios } from '@/common/axios';

import './assets/theme/default/style.scss';
import tableArrow from '/plugins/table-arrow/index';

configAxios();
window.$ = window.jQuery = require('jquery');
const app = createApp(App)
  .use(router)
  .use(store)
  .use(VueApexCharts)
  //стрелочки на таблицах, {speed: 45, selector: '.table-container'}
  //можно добавить опции скорость прокрутки и контейнер(стоит по умолчанию)
  .use(tableArrow)
  .use(FloatingVue);
app.component('Select-2', Select2);

app.config.globalProperties.$momentFormat = (date, format) =>
  date != null ? moment(date).locale('ru').format(format) : '';
// eslint-disable-next-line vue/multi-word-component-names
app.component('Pagination', Pagination);
app.component('VTooltip', Tooltip);
app.mount('#app');
if (localStorage.getItem('token')) {
  store.dispatch('auth/oidc/loadUser');
}

app.directive('click-outside', directiveClickOutside);
