export const routes = [
  {
    path: '/international-trade',
    component: () => import('@/app/modules/InternationalTrade/views/internationalTrade'),
  },
  { path: '/analitcs-services', component: () => import('@/app/modules/InternationalTrade/views/analitcsServices') },
  { path: '/analitcs-products', component: () => import('@/app/modules/InternationalTrade/views/analitcsProducts') },
  { path: '/trade-in-services', component: () => import('@/app/modules/InternationalTrade/views/tradeInServices') },
  { path: '/trade-agreements', component: () => import('@/app/modules/InternationalTrade/views/tradeAgreements') },
  {
    path: '/trade-analitics-import-export',
    component: () => import('@/app/modules/InternationalTrade/views/tradeAnaliticsImportExport'),
  },
  {
    path: '/trade-agreements-map',
    component: () => import('@/app/modules/InternationalTrade/views/tradeInvestmentCommissions'),
  },
  {
    path: '/trade-investment-agreements/:id?',
    component: () => import('@/app/modules/InternationalTrade/views/tradeInvestmentAgreements'),
  },
  {
    path: '/atlas-country/:id?',
    name: 'atlasCountry',
    component: () => import('@/app/modules/InternationalTrade/views/atlasCountry'),
  },
  {
    path: '/analitcs-services-striindex',
    component: () => import('@/app/modules/InternationalTrade/views/analitcsServicesStriindex'),
  },
];
