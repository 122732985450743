import moment from 'moment/moment';

export function getDefaultState() {
  return {
    initiative: {
      organizationInfo: '',
      applicant: '',
      address: '',
      phone: '',
      applicationSubmissionDate: moment().format('YYYY-MM-DD'),
      applicantGeneralInformation: '',
      measureTypeId: null,
      nsiTradingOperationId: null,
      userId: null,
      tovar: null,
      purpose: '',
      statusId: null,
      notes: '',
      proposedMeasureJustificationDto: {
        rfProductionVolumeForThreeYears: '',
        quotaVolume: '',
        consumptionVolumeForThreeYears: '',
        productDescription: '',
        foreignCompetingCompanies: '',
        rfProductionFacilitiesForThreeYears: '',
        workLoadLevelForThreeYears: '',
        consumptionShareForThreeYears: '',
        rfMarketPriceForThreeYears: '',
        productionUnitAverageCost: '',
        productionProfitabilityForThreeYears: '',
        tariffQuotaEfficiency: '',
        mainConsumers: '',
        applicantCommentOnCompensation: '',
      },
      requestFiles: [],
      dataFiles: [],
      rates: [],
      comments: [],
      timeline: [],
      responsibleEmployeeId: null,
      responsibleEmployee: null,
      employee: {
        fullName: '',
        organization: {
          nameFull: '',
          phone: '',
          address: '',
        },
      },
    },
  };
}
