import api from '@/app/modules/auth/api';

import refreshAuthorizationHeader from '@/common/axios';

export default {
  namespaced: true,
  state: {
    isExpired: false,
    profile: null,
    isLoggedOn: false,
  },

  mutations: {
    setUser(state, user) {
      state.profile = user;
      if (user) {
        localStorage.setItem('userProfileData', JSON.stringify(user));
        state.isLoggedOn = true;
      }
    },
    setIsExpired(state, val) {
      state.isExpired = !!val;
    },
    logout(state) {
      api.logout().then(() => {
        localStorage.removeItem('token');
        localStorage.removeItem('userProfileData');
        state.profile = null;
        state.isLoggedOn = false;
        refreshAuthorizationHeader();
      });
    },
  },
  actions: {
    loadUser(context) {
      refreshAuthorizationHeader();
      api
        .getProfileData()
        .then((response) => {
          context.commit('setUser', response.data);
        })
        .catch(() => {
          context.commit('setUser', null);
          context.commit('logout', null);
        });
    },
    logout({ commit }) {
      return new Promise((resolve) => {
        commit('logout');
        resolve();
      });
    },
  },
  getters: {
    isAuthenticated: (state) => {
      return state.profile != null; //&& !state.isExpired
    },
    getUserProfile: (state) => {
      return state.profile;
    },
  },
  modules: {},
};
