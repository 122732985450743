<template>
  <div class="social-media">
    <span class="social-media__heading"> Соцсети </span>
    <ul class="mt-8">
      <li>
        <a href="https://t.me/minec_russia" title="Официальный телеграм-канал" target="_blank">
          <img src="@/assets/img/svg/social-tg.svg" alt="Телеграмм" />
        </a>
      </li>
      <li>
        <a href="https://vk.com/minec_russia" title="Вконтакте" target="_blank">
          <img src="@/assets/img/svg/social-vk.svg" alt="Вконтакте" />
        </a>
      </li>
      <li>
        <a href="https://ok.ru/minec_russia" title="Мы в одноклассниках" target="_blank">
          <img src="@/assets/img/svg/social-ok.svg" alt="Одноклассники" />
        </a>
      </li>
      <li>
        <a href="https://zen.yandex.ru/id/623355e84009e444502fc288" title="Мы в Яндекс.Дзен" target="_blank">
          <img src="@/assets/img/svg/social-dz.svg" alt="Яндекс Дзен" />
        </a>
      </li>
      <li>
        <a href="https://rutube.ru/channel/999390/" title="RuTube" target="_blank">
          <img src="@/assets/img/svg/social-yt.svg" alt="RuTube" />
        </a>
      </li>
    </ul>
  </div>
</template>

<style scoped>
  .social-media__heading {
    font-size: 12px;
    font-weight: 600;
    color: #fff;
  }

  .social-media ul {
    font-size: 0;
    padding: 0;
    list-style: none;
  }

  .social-media li {
    display: inline-block;
    vertical-align: middle;
    margin-right: 1.125rem;
    padding: inherit;
    list-style: inherit;
  }

  .social-media li:last-child {
    margin-right: 0;
  }

  .social-media a {
    width: 22px;
    height: 20px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .social-media a:hover img {
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1);
  }

  .social-media img {
    color: red;
    display: block;
    max-width: 100%;
    max-height: 100%;
  }
</style>
